import StylesProductServiceInterface
  from '~/src/pages/category/[slug]/product/[slug]/styles/StylesProductServiceInterface'
import StylesProductServiceStyleOne
  from '~/src/pages/category/[slug]/product/[slug]/styles/StylesProductServiceStyleOne'
import StylesProductServiceStyleTwo
  from '~/src/pages/category/[slug]/product/[slug]/styles/StylesProductServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'

const getProductTemplateService = (style: TemplateEnum | null, muiTheme: CustomMuiTheme): StylesProductServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesProductServiceStyleTwo(muiTheme)
    default:
      return new StylesProductServiceStyleOne(muiTheme)
  }
}

export default getProductTemplateService
