import getProductTemplateService
  from '~/src/pages/category/[slug]/product/[slug]/styles/GetProductTemplateService'
import StylesProductServiceInterface
  from '~/src/pages/category/[slug]/product/[slug]/styles/StylesProductServiceInterface'
import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'
import { useLayout } from '~/src/hooks/layout/useLayout'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'

const useGetProductTemplate = (): StylesProductServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle('product')

  useLayout('product')

  return getProductTemplateService(pageStyle, muiTheme)
}

export default useGetProductTemplate
