import { useEoValue } from '~/src/hooks/useEoState'
import { NAVIGATE_BY_CATEGORIES_SELECTOR, NAVIGATE_BY_PRODUCT_MODAL_SELECTOR } from '~/src/stores/theme'
import Menu from '~/src/pages/menu'
import ProductPage from '~/src/pages/category/[slug]/product/[slug]'
import ProductsByCategories from '~/src/pages/category/[slug]/products'

const Product = () => {
  const navigateByCategories = useEoValue(NAVIGATE_BY_CATEGORIES_SELECTOR)
  const modalNavigation = useEoValue(NAVIGATE_BY_PRODUCT_MODAL_SELECTOR)

  if (modalNavigation && !navigateByCategories) {
    return <Menu />
  }

  if (modalNavigation && navigateByCategories) {
    return <ProductsByCategories />
  }

  return <ProductPage />
}

export default Product
