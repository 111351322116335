import { SxProps } from '@mui/material'
import StylesProductServiceInterface
  from '~/src/pages/category/[slug]/product/[slug]/styles/StylesProductServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesProductServiceStyleOne extends TemplateServiceStyleBase implements StylesProductServiceInterface {
  public getPageSx(): SxProps {
    return {
      width: '80%',
      mx: 'auto',
      mt: 4,
      [this.muiTheme.breakpoints.only('xs')]: {
        width: '100%'
      }
    }
  }
}
